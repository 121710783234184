import React from "react"
import { Layout, SEO, EventCard } from "../../components"
import { SUB_NAV_EVENTS_LINKS } from "../../utils/links"
import { graphql } from "gatsby"

export default function SocialEventsPage({ data }) {
  const images = data.allFile.edges

  const socials = [
    { title: "BODAS", image: images[3].node.childImageSharp.fluid.src, link: "bodas" },
    { title: "COMPROMISO", image: images[1].node.childImageSharp.fluid.src, link: "compromiso" },
    { title: "BAR MITZVÁ", image: images[0].node.childImageSharp.fluid.src, link: "barmitzva" },
    { title: "DESAYUNOS", image: images[2].node.childImageSharp.fluid.src, link: "desayunos" },
  ]
  return (
    <Layout showSubMenu={true} currentLink="Eventos" links={SUB_NAV_EVENTS_LINKS}>
      <SEO title="Eventos Sociales" />
      <section className="products">
        {socials.map((ev, idx) => (
          <EventCard key={idx} title={ev.title} image={ev.image} link={`sociales/${ev.link}`} />
        ))}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GET_SOCIAL_IMAGES {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/sociales/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              originalName
              src
              srcSet
              aspectRatio
              sizes
            }
          }
        }
      }
    }
  }
`
